<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="showDialog">
    <v-form @submit.prevent="editImage()">
      <v-card>
        <v-card-title>{{ $t('editImageDialog.title') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <img :src="imageReference.thumbnailURL" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="imageReference.when" :label="$t('editImageDialog.when')" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="showDialog = false">{{ $t('terms.cancel') }}</v-btn>
          <v-btn color="primary" type="submit">{{ $t('terms.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditImageDialog',
  props: {
    show: Boolean,
    zone: Object,
    imageReference: Object
  },

  data: () => ({
    imageDateTime: ''
  }),

  computed: {
    showDialog: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    editImage () {
      fetch(`/api/zones/${this.zone.id}/${this.imageReference.imageBaseName}`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify(this.imageReference)
      })
        .then(() => {
          this.showDialog = false
          this.$emit('reload')
        })
    }
  }
}
</script>
