<template>
  <div>
    <v-card>
      <v-card-title>Report for {{ zone.zoneName }}</v-card-title>
      <v-card-text>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
        <v-row>
          <v-col class="table-like">
            <div class="label">Usage:</div>
            <div>{{ zone.zoneUsage }}</div>
          </v-col>
          <v-col class="table-like">
            <div class="label">Size:</div>
            <div>{{ zone.zoneSize }} m2</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="table-like">
            <div class="label">Description:</div>
            <div>{{ zone.zoneDescription }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="( imageReference, index ) in zone.imageReferences" :key="index">
            <figure>
              <router-link :to="{ name: 'BigImage', params: { imagePath: imageReference.fullSizeURL } }">
                <img :src="imageReference.thumbnailURL" />
              </router-link>
              <figcaption>{{ format(imageReference.when) }}</figcaption>
              <v-icon @click.stop="deletePicture(imageReference)">mdi-delete</v-icon>
              <v-icon @click.stop="editPicture(imageReference)">mdi-pencil</v-icon>
            </figure>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <GrazingSessionReports :zone="zone" />
    <ZoneObservations :zone="zone" />

    <ConfirmDeleteImageFromZoneDialog :show="showConfirmDeleteImageFromZoneDialog" :zone="zone"
      :imageReference="imageReferenceToBeWorkedOn" @input="showConfirmDeleteImageFromZoneDialog = $event"
      v-on:reload="loadZone(zone.id)" />
    <EditImageDialog :show="showEditImageDialog" :zone="zone" :imageReference="imageReferenceToBeWorkedOn"
      @input="showEditImageDialog = $event" v-on:reload="loadZone(zone.id)" />
  </div>
</template>

<script>
import { formatDateTime } from '@/helpers'
import ConfirmDeleteImageFromZoneDialog from './ConfirmDeleteImageFromZoneDialog.vue'
import EditImageDialog from './EditImageDialog.vue'
import GrazingSessionReports from '../grazing/GrazingSessionReports.vue'
import ZoneObservations from './ZoneObservations.vue'

export default {
  name: 'ZoneReport',

  components: { ConfirmDeleteImageFromZoneDialog, EditImageDialog, GrazingSessionReports, ZoneObservations },

  data: () => ({
    error: null,
    zone: {},
    showConfirmDeleteImageFromZoneDialog: false,
    showEditImageDialog: false,
    imageReferenceToBeWorkedOn: {}
  }),

  beforeMount () {
    this.loadZone(this.$route.params.zoneId)
  },

  methods: {
    format (value) {
      return formatDateTime(value)
    },

    loadZone (zoneId) {
      fetch(`/api/zones/${zoneId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status !== 200) { this.error = response.statusText }
          return response
        })
        .then((response) => response.json())
        .then((data) => {
          if (!this.error) { this.zone = data }
        })
        .catch((error) => {
          this.error = error
        })
    },

    deletePicture (imageReference) {
      this.imageReferenceToBeWorkedOn = imageReference
      this.showConfirmDeleteImageFromZoneDialog = true
    },

    editPicture (imageReference) {
      this.imageReferenceToBeWorkedOn = imageReference
      this.showEditImageDialog = true
    }
  }
}
</script>

<style>
DIV.table-like {
  display: grid;
  grid-template-columns: auto 100%;
  gap: 1em;
}
</style>
