<template>
  <v-card>
    <v-card-title>Zone Observations</v-card-title>
    <v-card-text>
      <v-data-table data-cy="observations" :items="zone.observations" :headers="headers">
        <template v-slot:[`item.when`]="{ item }">
          {{ formatDateTime(item.when) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatting from '@/mixins/formatting'

export default {
  name: 'ZoneObservations',
  mixins: [formatting],

  props: {
    zone: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    headers: [
      { text: 'When', value: 'when' },
      { text: 'Property', value: 'property' },
      { text: 'Observed', value: 'observed' }
    ]
  })

}
</script>
