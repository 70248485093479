<template>
  <v-card>
    <v-card-title>Grazing sessions at {{ zone.zoneName }}</v-card-title>
    <v-card-text>
      <v-row v-for="(session, index) in sessions" :key="index">
        <v-col>
          Access Opened: {{ formatDate(session.accessOpened) }}
        </v-col>
        <v-col>
          Access Closed: {{ formatDate(session.accessClosed) }}
        </v-col>
        <v-col>
          Session Duration: {{ sessionDuration(session.accessOpened, session.accessClosed) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Average session duration: {{ averageSessionDuration() }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import formatting from '../../mixins/formatting'

export default {
  name: 'GrazingSessionReports',
  mixins: [formatting],

  props: {
    zone: Object
  },

  data: () => ({
    sessions: []
  }),

  watch: {
    zone: function (value) {
      if (value) {
        this.sessions = this.zone.grazingSessions
      }
    }
  },

  methods: {
    sessionDuration (accessOpened, accessClosed) {
      if (accessOpened && accessClosed) {
        const opened = new Date(accessOpened)
        const closed = new Date(accessClosed)
        const duration = closed.getTime() - opened.getTime()
        return this.formatDuration(duration / 1000)
      }
      return ''
    },

    // TODO move this to the backend as part of GrazingZone
    averageSessionDuration () {
      const sessions = this.sessions
      let numberOfSessions = sessions.length

      if (sessions.length > 0) {
        const totalDuration = sessions.reduce((total, session) => {
          if (session.accessClosed === null) {
            numberOfSessions--
            return total
          }

          const opened = new Date(session.accessOpened)
          const closed = new Date(session.accessClosed)
          const duration = closed.getTime() - opened.getTime()
          return total + duration
        }, 0)
        const averageDuration = totalDuration / numberOfSessions
        return this.formatDuration(averageDuration / 1000)
      }
      return ''
    }
  }

}
</script>
